<template>
    <div id="event_show">
        <inc_header></inc_header>

        <div class="container">
            <div class="banner"><el-image :src="banner.srcPc" fit="cover"></el-image></div>

            <div class="show">
                <h2>{{ event.category }}</h2>
                <h1>{{ event.sign }}</h1>
                <div v-html="event.content"></div>
            </div>
        </div>

        <inc_footer></inc_footer>
    </div>
</template>

<style scoped>
    .banner { height:480px; }
    .banner .el-image { width:100%; height:100%; }

    .show { margin:60px 0; color:#666; }
    .show h1 { margin:15px 0 50px 0; font-size:24px; color:#333; }
    .show h2 { margin:0; font-size:18px; color:#fcc; }
    .show img { max-width:100%; }
</style>

<script>
    import inc_header from "./../components/inc_header.vue"
    import inc_footer from "./../components/inc_footer.vue"

    export default {
        name: "event_show",
        components: {
            inc_header,
            inc_footer
        },
        data() {
            return {
                banner: {},  // Banner
                event: {}  // 资讯
            }
        },
        created() {
            let that = this;

            // 获取“Banner”
            this.$axios.post(this.$global.config.serverApi+'/banner.php',{ action:"getByCategory", category:"event" }).then( function(response) {
                if (response.data.complete === true) {
                    that.banner = response.data.banner;
                } else {
                    throw new Error(response.data.error);
                }
            }).catch( function(error) {
                that.$store.commit('setAlert', error.message);  // 弹出错误提示
            });

            // 获取“资讯”
            this.$axios.post(this.$global.config.serverApi+'/event.php', { action:'get', eventId:this.$route.params.id }).then( function(response) {
                if (response.data.complete === true) {
                    that.event = response.data.event;
                } else {
                    throw new Error(response.data.error);
                }
            }).catch( function(error) {
                that.$store.commit('setAlert', error.message);  // 弹出错误提示
            });
        }
    }
</script>
